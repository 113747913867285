import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const QrWelcome = () => {
  const navigate = useNavigate();
  const os = window.navigator.userAgentData.platform;

  console.log(os);

  useEffect(() => {
    navigate("/");
  }, [navigate]);
};

export default QrWelcome;
