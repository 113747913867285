import { FaInstagram, FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";
import logo from "../assets/logo.png";
import logoMobile from "../assets/logo_mobile.png";

const PrivacyPolicy = () => {
  return (
    <div className="bg-black min-h-screen flex flex-col font-manrope w-full overflow-hidden">
      <div className="fixed w-full flex justify-between items-center p-4 bg-black z-20 iPadPro:p-4 lg:px-24">
        <a href="/">
          <img
            src={logo}
            alt="logo"
            className="hidden lg:block lg:h-[40px] h-[30px] cursor-pointer"
          />
          <img
            src={logoMobile}
            alt="logoMobile"
            className="lg:hidden block lg:h-[40px] h-[30px] cursor-pointer"
          />
        </a>
        <div className="flex justify-between items-center lg:gap-16 md:gap-8 iPadPro:gap-8 gap-4">
          <a
            href="https://www.instagram.com/scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <a
            href="https://twitter.com/scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <a
            href="https://www.tiktok.com/@scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaTiktok
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <FaYoutube
            color="white"
            className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
          />
        </div>
      </div>
      <div className="text-white w-full z-10 mt-12 iPadPro:mt-16 lg:mt-32 p-4 iPadPro:p-4 lg:px-24 flex flex-col gap-4">
        <p className="font-bold text-4xl md:text-7xl iPadPro:text-8xl lg:text-9xl ">
          PRIVACY POLICY
        </p>
        <span className="font-normal">Last updated November 20, 2023</span>
        <p>
          This privacy notice for Scout ('<b>we</b>', '<b>us</b>', or '
          <b>our</b>'), describes how and why we might collect, store, use,
          and/or share ('<b>process</b>') your information when you use our
          services ('<b>Services</b>'), such as when you: - Visit our
          website at 
          <a className="font-bold" href="http://www.scout.frl/">
            http://www.scout.frl
          </a>
          , or any website of ours that links to this privacy notice - Download
          and use our mobile application (Scout: Bring it), or any other
          application of ours that links to this privacy notice - Engage with us
          in other related ways, including any sales, marketing, or events.
          <br />
          <b>Questions or concerns?</b> Reading this privacy notice will help
          you understand your privacy rights and choices. If you do not agree
          with our policies and practices, please do not use our Services. If
          you still have any questions or concerns, please contact us at 
          <a className="font-bold" href="mailto:questions@scout.frl">
            questions@scout.frl
          </a>
        </p>
        <p className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold">
          SUMMARY OF KEY POINTS
        </p>
        <b>
          <i>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our 
            <a href="#toc">table of contents</a> below to find the section you
            are looking for.
          </i>
        </b>
        <p>
          <b>What personal information do we process?</b> When you visit, use,
          or navigate our Services, we may process personal information
          depending on how you interact with us and the Services, the choices
          you make, and the products and features you use. Learn more about 
          <a href="#personalinfo">personal information you disclose to us.</a>
        </p>
        <p>
          <b>Do we process any sensitive personal information?</b> We do not
          process sensitive personal information.
          <br />
          <br />
          <b>Do we receive any information from third parties?</b> We do not
          receive any information from third parties.
          <br />
          <br />
          <b>How do we process your information?</b> We process your information
          to provide, improve, and administer our Services, communicate with
          you, for security and fraud prevention, and to comply with law. We may
          also process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so. Learn more about 
          <a href="#infouse">how we process your information.</a>
          <br />
          <br />
          <b>
            In what situations and with which parties do we share personal
            information?
          </b>
           We may share information in specific situations and with
          specific third parties. Learn more about 
          <a href="#whoshare">
            when and with whom we share your personal information.
          </a>
          <br />
          <br />
          <b>How do we keep your information safe?</b> We
          have organisational and technical processes and procedures in place to
          protect your personal information. However, no electronic transmission
          over the internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Learn more about 
          <a href="#infosafe">how we keep your information safe.</a>
          <br />
          <br />
          <b>What are your rights?</b> Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Learn more about 
          <a href="#privacyrights">your privacy rights.</a>
          <br />
          <br />
          <b>How do you exercise your rights?</b> The easiest way to exercise
          your rights is by submitting a 
          <a href="https://app.termly.io/notify/e060226b-a9f4-4eae-ae4e-a27747c8d593">
            data subject access request,
          </a>{" "}
          or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <p>
          Want to learn more about what we do with any information we
          collect? Review the privacy notice in full below.
        </p>
        <p
          id="toc"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold"
        >
          TABLE OF CONTENTS
        </p>
        <a href="#infocollect">
          <u>1. WHAT INFORMATION DO WE COLLECT?</u>
        </a>
        <a href="#infouse">
          <u>2. HOW DO WE PROCESS YOUR INFORMATION?</u>
        </a>
        <a href="#legalbases">
          <u>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </u>
        </a>
        <a href="#whoshare">
          <u>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</u>
        </a>
        <a href="#cookies">
          <u>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</u>
        </a>
        <a href="#inforetain">
          <u>6. HOW LONG DO WE KEEP YOUR INFORMATION?</u>
        </a>
        <a href="#infosafe">
          <u>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</u>
        </a>
        <a href="#infominors">
          <u>8. DO WE COLLECT INFORMATION FROM MINORS?</u>
        </a>
        <a href="#privacyrights">
          <u>9. WHAT ARE YOUR PRIVACY RIGHTS?</u>
        </a>
        <a href="#DNT">
          <u>10. CONTROLS FOR DO-NOT-TRACK FEATURES</u>
        </a>
        <a href="#uslaws">
          <u>11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</u>
        </a>
        <a href="#otherlaws">
          <u>12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</u>
        </a>
        <a href="#policyupdates">
          <u>13. DO WE MAKE UPDATES TO THIS NOTICE?</u>
        </a>
        <a href="#contact">
          <u>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u>
        </a>
        <a href="#request">
          <u>
            15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </u>
        </a>
        <p
          id="infocollect"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-0 md:scroll-mt-20 iPadPro:scroll-mt-20 lg:scroll-mt-20"
        >
          1. WHAT INFORMATION DO WE COLLECT?
        </p>
        <b>Personal information you disclose to us</b>
        <p>
          <b>
            <i>In Short: </i>
          </b>
          We collect personal information that you provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services,Â express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <b>Personal Information Provided by You.</b> The personal information
          that we collect depends on the context of your interactions with us
          and the Services, the choices you make, and the products and features
          you use. The personal information we collect may include the
          following:
        </p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>names</li>
          <li>phone numbers</li>
          <li>email addresses</li>
          <li>usernames</li>
          <li>passwords</li>
          <li>contact preferences</li>
          <li>contact or authentication data</li>
          <li>debit/credit card numbers</li>
          <li>billing addresses</li>
        </ul>
        <p>
          <b>Sensitive Information.</b> We do not process sensitive information.
        </p>
        <p>
          <b>Application Data.</b> If you use our application(s), we also may
          collect the following information if you choose to provide us with
          access or permission:
        </p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>
            <i>Geolocation Information.</i> We may request access or permission
            to track location-based information from your mobile device, either
            continuously or while you are using our mobile application(s), to
            provide certain location-based services. If you wish to change our
            access or permissions, you may do so in your device's settings.
          </li>
          <li>
            <i>Mobile Device Access.</i> We may request access or permission to
            certain features from your mobile device, including your mobile
            device's camera, contacts, microphone, storage, and other features.
            If you wish to change our access or permissions, you may do so in
            your device's settings.
          </li>
          <li>
            <i>Mobile Device Data.</i> We automatically collect device
            information (such as your mobile device ID, model, and
            manufacturer), operating system, version information and system
            configuration information, device and application identification
            numbers, browser type and version, hardware model Internet service
            provider and/or mobile carrier, and Internet Protocol (IP) address
            (or proxy server). If you are using our application(s), we may also
            collect information about the phone network associated with your
            mobile device, your mobile device's operating system or platform,
            the type of mobile device you use, your mobile device's unique
            device ID, and information about the features of our application(s)
            you accessed.
          </li>
          <li>
            <i>Push Notifications.</i> We may request to send you push
            notifications regarding your account or certain features of the
            application(s). If you wish to opt out from receiving these types of
            communications, you may turn them off in your device's settings.
          </li>
        </ul>
        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
          <br />
          <br />
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <b>Information automatically collected</b>
        <p>
          <b>In Short: </b>
          Some information, such as your Internet Protocol (IP) address and/or
          browser and device characteristics, are collected automatically when
          you visit our Services.
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <p>The information we collect includes:</p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>
            <i>Log and Usage Data.</i> Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services (such as the date/time stamps associated with your usage,
            pages and files viewed, searches, and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called 'crash dumps'), and
            hardware settings).
          </li>
          <li>
            <i>Device Data.</i> We collect device data such as information about
            your computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </li>
          <li>
            <i>Location Data.</i> We collect location data such as information
            about your device's location, which can be either precise or
            imprecise. How much information we collect depends on the type and
            settings of the device you use to access the Services. For example,
            we may use GPS and other technologies to collect geolocation data
            that tells us your current location (based on your IP address). You
            can opt out of allowing us to collect this information either by
            refusing access to the information or by disabling your Location
            setting on your device. However, if you choose to opt out, you may
            not be able to use certain aspects of the Services.
          </li>
        </ul>
        <p
          id="infouse"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </p>
        <i>
          <b>In Short:</b> We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </i>
        <b>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </b>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>
            <b>
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </b>
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <b>To deliver and facilitate delivery of services to the user.</b>
            We may process your information to provide you with the requested
            service.
          </li>
          <li>
            <b>To respond to user inquiries/offer support to users.</b>
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </li>
          <li>
            <b>To send administrative information to you.</b>
            We may process your information to send you details about our
            products and services, changes to our terms and policies, and other
            similar information.
          </li>
          <li>
            <b>To fulfil and manage your orders.</b>
            We may process your information to fulfil and manage your orders,
            payments, returns, and exchanges made through the Services.
          </li>
          <li>
            <b>To enable user-to-user communications.</b>
            We may process your information if you choose to use any of our
            offerings that allow for communication with another user.
          </li>
          <li>
            <b>To request feedback.</b>
            We may process your information when necessary to request feedback
            and to contact you about your use of our Services.
          </li>
          <li>
            <b>To send you marketing and promotional communications.</b>
            We may process the personal information you send to us for our
            marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time.
            For more information, see{" "}
            <a href="#privacyrights">
              <u>'WHAT ARE YOUR PRIVACY RIGHTS?'</u>
            </a>{" "}
            below.
          </li>
          <li>
            <b>To deliver targeted advertising to you.</b>
            We may process your information to develop and display personalised
            content and advertising tailored to your interests, location, and
            more.
          </li>
          <li>
            <b>To protect our Services.</b>
            We may process your information as part of our efforts to keep our
            Services safe and secure, including fraud monitoring and prevention.
          </li>
          <li>
            <b>To identify usage trends.</b>
            We may process information about how you use our Services to better
            understand how they are being used so we can improve them.
          </li>
          <li>
            <b>
              To determine the effectiveness of our marketing and promotional
              campaigns.
            </b>
            We may process your information to better understand how to provide
            marketing and promotional campaigns that are most relevant to you.
          </li>
          <li>
            <b>To save or protect an individual's vital interest.</b>
            We may process your information when necessary to save or protect an
            individual’s vital interest, such as to prevent harm.
          </li>
        </ul>
        <p
          id="legalbases"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </p>
        <i>
          <b>In Short:</b> We only process your personal information when we
          believe it is necessary and we have a valid legal reason (i.e. legal
          basis) to do so under applicable law, like with your consent, to
          comply with laws, to provide you with services to enter into
          or fulfil our contractual obligations, to protect your rights, or
          to fulfil our legitimate business interests.
        </i>
        <b>
          <u>
            <i>
              If you are located in the EU or UK, this section applies to you.
            </i>
          </u>
        </b>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>
            <b>Consent.</b> We may process your information if you have given us
            permission (i.e. consent) to use your personal information for a
            specific purpose. You can withdraw your consent at any time. Learn
            more about <a href="#withdrawconsent">withdrawing your consent.</a>
          </li>
          <li>
            <b>Performance of a Contract.</b>
            We may process your personal information when we believe it is
            necessary to fulfil our contractual obligations to you, including
            providing our Services or at your request prior to entering into a
            contract with you.
          </li>
          <li>
            <b>Legitimate Interests.</b>
            We may process your information when we believe it is reasonably
            necessary to achieve our legitimate business interests and those
            interests do not outweigh your interests and fundamental rights and
            freedoms. For example, we may process your personal information for
            some of the purposes described in order to:
          </li>
          <li>
            Send users information about special offers and discounts on our
            products and services
          </li>
          <li>
            Develop and display personalised and relevant advertising content
            for our users
          </li>
          <li>
            Analyse how our Services are used so we can improve them to engage
            and retain users
          </li>
          <li>Support our marketing activities</li>
          <li>Diagnose problems and/or prevent fraudulent activities</li>
          <li>
            Understand how our users use our products and services so we can
            improve user experience
          </li>
          <li>
            <b>Legal Obligations.</b>
            We may process your information where we believe it is necessary for
            compliance with our legal obligations, such as to cooperate with a
            law enforcement body or regulatory agency, exercise or defend our
            legal rights, or disclose your information as evidence in litigation
            in which we are involved.
          </li>
          <li>
            <b>Vital Interests.</b>
            We may process your information where we believe it is necessary to
            protect your vital interests or the vital interests of a third
            party, such as situations involving potential threats to the safety
            of any person.
          </li>
        </ul>
        <b>
          <u>
            <i>If you are located in Canada, this section applies to you.</i>
          </u>
        </b>
        <p>
          We may process your information if you have given us specific
          permission (i.e. express consent) to use your personal information for
          a specific purpose, or in situations where your permission can be
          inferred (i.e. implied consent). You can 
          <a href="#withdrawconsent">withdraw your consent</a>
           at any time.
        </p>
        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </li>
          <li>For investigations and fraud detection and prevention</li>
          <li>For business transactions provided certain conditions are met</li>
          <li>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </li>
          <li>
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </li>
          <li>
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse
          </li>
          <li>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </li>
          <li>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </li>
          <li>
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </li>
          <li>
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </li>
          <li>
            If the information is publicly available and is specified by the
            regulations
          </li>
        </ul>
        <p
          id="whoshare"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </p>
        <i>
          <b>In Short:</b> We may share information in specific situations
          described in this section and/or with the following third parties.
        </i>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>
            <b>Business Transfers.</b>
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </li>
          <li>
            <b>Other Users.</b>
            When you share personal information (for example, by posting
            comments, contributions, or other content to the Services) or
            otherwise interact with public areas of the Services, such personal
            information may be viewed by all users and may be publicly made
            available outside the Services in perpetuity. Similarly, other users
            will be able to view descriptions of your activity, communicate with
            you within our Services, and view your profile.
          </li>
          <li>
            <b>Offer Wall.</b>
            Our application(s) may display a third-party hosted 'offer
            wall'. Such an offer wall allows third-party advertisers to offer
            virtual currency, gifts, or other items to users in return for the
            acceptance and completion of an advertisement offer. Such an offer
            wall may appear in our application(s) and be displayed to you based
            on certain data, such as your geographic area or demographic
            information. When you click on an offer wall, you will be brought to
            an external website belonging to other persons and will leave our
            application(s). A unique identifier, such as your user ID, will be
            shared with the offer wall provider in order to prevent fraud and
            properly credit your account with the relevant reward.
          </li>
        </ul>
        <p
          id="cookies"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </p>
        <i>
          <b>In Short:</b> We may use cookies and other tracking technologies to
          collect and store your information.
        </i>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
        <p
          id="inforetain"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
        </p>
        <i>
          <b>In Short:</b> We keep your information for as long as necessary
          to fulfil the purposes outlined in this privacy notice unless
          otherwise required by law.
        </i>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer
          than Â  the period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <p
          id="infosafe"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          7. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </p>
        <i>
          <b>In Short:</b> We aim to protect your personal information through a
          system of organisational and technical security measures.
        </i>
        <p>
          We have implemented appropriate and reasonable technical
          and organisational security measures designed to protect the security
          of any personal information we process. However, despite our
          safeguards and efforts to secure your information, no electronic
          transmission over the Internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorised third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Although we will do our best to
          protect your personal information, transmission of personal
          information to and from our Services is at your own risk. You should
          only access the Services within a secure environment.
        </p>
        <p
          id="infominors"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          8. DO WE COLLECT INFORMATION FROM MINORS?
        </p>
        <i>
          <b>In Short:</b> We do not knowingly collect data from or market
          to children under 18 years of age.
        </i>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent's use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at 
          <a href="mailto:questions@scout.frl">questions@scout.frl</a>.
        </p>
        <p
          id="privacyrights"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          9. WHAT ARE YOUR PRIVACY RIGHTS?
        </p>
        <i>
          <b>In Short:</b> In some regions, such as the European Economic Area
          (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights
          that allow you greater access to and control over your personal
          information.Â You may review, change, or terminate your account at any
          time.
        </i>
        <p>
          In some regions (like the EEA, UK, Switzerland, and Canada), you have
          certain rights under applicable data protection laws. These may
          include the right (i) to request access and obtain a copy of your
          personal information, (ii) to request rectification or erasure; (iii)
          to restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision-making. In certain circumstances, you may also have
          the right to object to the processing of your personal information.
          You can make such a request by contacting us by using the contact
          details provided in the section 
          <a href="#contact">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a>
           below.
        </p>
        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your 
          <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            Member State data protection authority
          </a>
           or 
          <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
            UK data protection authority
          </a>
          .
        </p>
        <p>
          If you are located in Switzerland, you may contact the 
          <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
            Federal Data Protection and Information Commissioner.
          </a>
        </p>
        <p>
          <b>
            <u>Withdrawing your consent:</u>
          </b>
           If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section 
          <a href="#contact">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a>
          below or updating your preferences.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <p>
          <b>
            <u>Opting out of marketing and promotional communications:</u>
          </b>
           You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails that we
          send, or by contacting us using the details provided in the section 
          <a href="#contact">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a>
           below. You will then be removed from the marketing lists. However, we
          may still communicate with you: for example, to send you
          service-related messages that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes.
        </p>
        <b>Account Information</b>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>
          <b>
            <u>Cookies and similar technologies:</u>
          </b>
           Most Web browsers are set to accept cookies by default. If you
          prefer, you can usually choose to set your browser to remove cookies
          and to reject cookies. If you choose to remove cookies or reject
          cookies, this could affect certain features or services of our
          Services.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at 
          <a href="mailto:questions@scout.frl">questions@scout.frl.</a>
        </p>
        <p
          id="DNT"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          10. CONTROLS FOR DO-NOT-TRACK FEATURES
        </p>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ('DNT') feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognising and implementing DNT
          signals has been finalised. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <p
          id="uslaws"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </p>
        <i>
          <b>In Short:</b> If you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </i>
        <b>What categories of personal information do we collect?</b>
        <p>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>
        <table className="w-full lg:w-1/2 border-[1px]">
          <tr>
            <th className="border-[1px] text-left p-2">Category</th>
            <th className="border-[1px] text-left p-2">Examples</th>
            <th className="border-[1px] text-left p-2">Collected</th>
          </tr>
          <tr>
            <td className="border-[1px] p-2">A. Identifiers</td>
            <td className="border-[1px] w-4/6 p-2">
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address, and
              account name
            </td>
            <td className="border-[1px] p-2">YES</td>
          </tr>
        </table>
        <table className="w-full lg:w-1/2 border-[1px]">
          <tr>
            <td className="border-[1px] p-2">
              B. Personal information as defined in the California Customer
              Records statute
            </td>
            <td className="border-[1px] w-1/2 p-2">
              Name, contact information, education, employment, employment
              history, and financial information
            </td>
            <td className="border-[1px] p-2">NO</td>
          </tr>
        </table>
        <table className="w-full lg:w-1/2 border-[1px]">
          <tr>
            <td className="border-[1px] p-2">
              C. Protected classification characteristics under state or federal
              law
            </td>
            <td className="border-[1px] w-1/2 p-2">Gender and date of birth</td>
            <td className="border-[1px] p-2">YES</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">D. Commercial information</td>
            <td className="border-[1px] w-1/2 p-2">
              Transaction information, purchase history, financial details, and
              payment information
            </td>
            <td className="border-[1px] p-2">YES</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">E. Biometric information</td>
            <td className="border-[1px] w-1/2 p-2">
              Fingerprints and voiceprints
            </td>
            <td className="border-[1px] p-2">NO</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">
              F. Internet or other similar network activity
            </td>
            <td className="border-[1px] w-1/2 p-2">
              Browsing history, search history, online behaviour, interest data,
              and interactions with our and other websites, applications,
              systems, and advertisements
            </td>
            <td className="border-[1px] p-2">NO</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">G. Geolocation data</td>
            <td className="border-[1px] w-1/2 p-2">Device location</td>
            <td className="border-[1px] p-2">YES</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </td>
            <td className="border-[1px] w-1/2 p-2">
              Images and audio, video or call recordings created in connection
              with our business activities
            </td>
            <td className="border-[1px] p-2">YES</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">
              I. Professional or employment-related information
            </td>
            <td className="border-[1px] w-1/2 p-2">
              Business contact details in order to provide you our Services at a
              business level or job title, work history, and professional
              qualifications if you apply for a job with us
            </td>
            <td className="border-[1px] p-2">NO</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">J. Education Information</td>
            <td className="border-[1px] w-1/2 p-2">
              Student records and directory information
            </td>
            <td className="border-[1px] p-2">NO</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">
              K. Inferences drawn from collected personal information
            </td>
            <td className="border-[1px] w-1/2 p-2">
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individualâ€™s preferences and characteristics
            </td>
            <td className="border-[1px] p-2">NO</td>
          </tr>
          <tr>
            <td className="border-[1px] p-2">
              L. Sensitive personal Information
            </td>
            <td className="border-[1px] w-1/2 p-2"></td>
            <td className="border-[1px] p-2">NO</td>
          </tr>
        </table>
        <p>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>Category A - As long as the user has an account with us</li>
          <li>Category B - As long as the user has an account with us</li>
          <li>Category C - As long as the user has an account with us</li>
          <li>Category D - As long as the user has an account with us</li>
          <li>Category G - 1 year</li>
          <li>Category H - As long as the user has an account with us</li>
        </ul>
        <p>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </li>
        </ul>
        <b>How do we use and share your personal information?</b>
        <p>
          Learn about how we use your personal information in the section, 
          <a href="#infouse">'HOW DO WE PROCESS YOUR INFORMATION?'</a>
        </p>
        <b>Will your information be shared with anyone else?</b>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Learn more about how we disclose personal information to in the
          section,
          <a href="#whoshare">
            'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'
          </a>
        </p>
        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to
          be 'selling' of your personal information.
        </p>
        <p>
          We have not disclosed, sold, or shared any personal information to
          third parties for a business or commercial purpose in the preceding
          twelve (12) months. WeÂ will not sell or share personal information in
          the future belonging to website visitors, users, and other consumers.
        </p>
        <b>California Residents</b>
        <p>
          California Civil Code Section 1798.83, also known as the 'Shine The
          Light' law permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>
        <b>
          <u>CCPA Privacy Notice</u>
        </b>
        <p>
          This section applies only to California residents. Under the
          California Consumer Privacy Act (CCPA), you have the rights listed
          below.
          <br />
          <br />
          The California Code of Regulations defines a 'residents' as:
          <br />
          <br />
          (1) every individual who is in the State of California for other than
          a temporary or transitory purpose and
          <br />
          <br />
          (2) every individual who is domiciled in the State of California who
          is outside the State of California for a temporary or transitory
          purpose
          <br />
          <br />
          All other individuals are defined as 'non-residents'.
          <br />
          <br />
          If this definition of 'resident' applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </p>
        <b>Your rights with respect to your personal data</b>
        <u>Right to request deletion of the data: Request to delete</u>
        <p>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation, or any processing that
          may be required to protect against illegal activities.
        </p>
        <u>Right to be informed: Request to know</u>
        <p>Depending on the circumstances, you have a right to know:</p>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>whether we collect and use your personal information;</li>
          <li>the categories of personal information that we collect;</li>
          <li>
            the purposes for which the collected personal information is used;
          </li>
          <li>
            whether we sell or share personal information to third parties;
          </li>
          <li>
            the categories of personal information that we sold, shared, or
            disclosed for a business purpose;
          </li>
          <li>
            the categories of third parties to whom the personal information was
            sold, shared, or disclosed for a business purpose;
          </li>
          <li>
            the business or commercial purpose for collecting, selling, or
            sharing personal information; and
          </li>
          <li>
            the specific pieces of personal information we collected about you.
          </li>
        </ul>
        <p>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>
        <u>
          Right to Non-Discrimination for the Exercise of a Consumer's Privacy
          Rights
        </u>
        <p>
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>
        <u>
          Right to Limit Use and Disclosure of Sensitive Personal Information
        </u>
        <p>We do not process consumer's sensitive personal information.</p>
        <u>Verification process</u>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g. phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </p>
        <p>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. However, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </p>
        <u>Other privacy rights</u>
        <ul className="list-disc px-4 iPadPro:px-4 lg:px-24">
          <li>
            You may object to the processing of your personal information.
          </li>
          <li>
            You may request correction of your personal data if it is incorrect
            or no longer relevant, or ask to restrict the processing of the
            information.
          </li>
          <li>
            You can designate an authorised agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorised agent
            that does not submit proof that they have been validly authorised to
            act on your behalf in accordance with the CCPA.
          </li>
          <li>
            You may request to opt out from future selling or sharing of your
            personal information to third parties. Upon receiving an opt-out
            request, we will act upon the request as soon as feasibly possible,
            but no later than fifteen (15) days from the date of the request
            submission.
          </li>
        </ul>
        <p>
          To exercise these rights, you can contact us by submitting a 
          <a href="https://app.termly.io/notify/e060226b-a9f4-4eae-ae4e-a27747c8d593">
            data subject access request
          </a>
          , or by referring to the contact details at the bottom of this
          document. If you have a complaint about how we handle your data, we
          would like to hear from you.
        </p>
        <p
          id="otherlaws"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
        </p>
        <i>
          <b>In Short:</b> You may have additional rights based on the country
          you reside in.
        </i>
        <b>Republic of South Africa</b>
        <p>
          At any time, you have the right to request access to or correction of
          your personal information. You can make such a request by contacting
          us by using the contact details provided in the section{" "}
          <a href="#request">
            'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?'
          </a>
        </p>
        <p>
          If you are unsatisfied with the manner in which we address any
          complaint with regard to our processing of personal information, you
          can contact the office of the regulator, the details of which are:
        </p>
        <a href="https://inforegulator.org.za/">
          The Information Regulator (South Africa)
        </a>
        <p>
          General enquiries: 
          <a href="mailto:enquiries@inforegulator.org.za">
            enquiries@inforegulator.org.za
          </a>
        </p>
        <p>
          Complaints (complete POPIA/PAIA form
          <br />
          5): 
          <a href="mailto:PAIAComplaints@inforegulator.org.za">
            PAIAComplaints@inforegulator.org.za
          </a>
           & 
          <a href="mailto:POPIAComplaints@inforegulator.org.za">
            POPIAComplaints@inforegulator.org.za
          </a>
        </p>
        <p
          id="policyupdates"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          13. DO WE MAKE UPDATES TO THIS NOTICE?
        </p>
        <i>
          <b>In Short:</b> Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </i>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated 'Revised' date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <p
          id="contact"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </p>
        <p>
          If you have questions or comments about this notice, you may email us
          at <a href="mailto:questions@scout.frl">questions@scout.frl</a>{" "}
          or contact us by post at:
        </p>
        <p>Scout</p>
        <p>__________</p>
        <p>__________</p>
        <p>South Africa</p>
        <p
          id="request"
          className="text-xl md:text-xl iPadPro:text-xl lg:text-2xl font-semibold scroll-mt-20"
        >
          15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </p>
        <p>
          You have the right to request access to the personal information we
          collect from you, change that information, or delete it. To request to
          review, update, or delete your personal information, please fill out
          and submit a{" "}
          <a href="https://app.termly.io/notify/e060226b-a9f4-4eae-ae4e-a27747c8d593">
            data subject access request.
          </a>
        </p>
        <p>
          This privacy policy was created using Termly's 
          <a href="https://termly.io/products/privacy-policy-generator/">
            Privacy Policy Generator.
          </a>
        </p>
      </div>
      <div className="flex flex-col lg:flex-row items-start lg:items-center w-full/2">
        <p className="lg:my-20 mt-11 px-4 text-white text-sm lg:text-[16px] lg:pl-24">
          © 2023 SCOUT All rights reserved
        </p>
        <a
          href="/"
          className="lg:my-20 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          Home
        </a>
        <a
          href="https://scout.simple.ink/"
          target="_blank"
          rel="noreferrer"
          className="lg:my-20 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          Support
        </a>
        <a
          href="https://app.termly.io/document/eula/b19a4585-0385-486f-9087-3e75de9728ae"
          target="_blank"
          rel="noreferrer"
          className="lg:my-20 mb-11 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          End User Licensing Agreement
        </a>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
