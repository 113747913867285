import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import QrWelcome from "./pages/QrWelcome";
import Share from "./pages/Share";

export const routes = [
  { path: "/", element: <Home /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/share", element: <Share /> },
  { path: "/qr-welcome", element: <QrWelcome /> },
  { path: "*", element: <NotFound /> },
];
