import { FaInstagram, FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";
import logo from "../assets/logo.png";
import logoMobile from "../assets/logo_mobile.png";
import { useSearchParams } from "react-router-dom";
import image from "../assets/image.jpg";
import video from "../assets/video.mp4";
import appStoreButton from "../assets/AppStoreButton.png";
import { useEffect } from "react";

const Share = () => {
  const screenWidth = window.innerWidth;
  const [params] = useSearchParams();
  const link = params.get("link");

  useEffect(() => {
    if (screenWidth < 767 && !!link) {
      window.location.replace(link);
    }
  }, [screenWidth, link]);

  return (
    <div className="bg-black min-h-screen md:flex hidden flex-col font-manrope w-full overflow-hidden">
      <div className="w-full flex justify-between items-center p-4 bg-black z-20 iPadPro:p-4 lg:px-24">
        <a href="/">
          <img
            src={logo}
            alt="logo"
            className="hidden lg:block lg:h-[40px] h-[30px] cursor-pointer"
          />
          <img
            src={logoMobile}
            alt="logoMobile"
            className="lg:hidden block lg:h-[40px] h-[30px] cursor-pointer"
          />
        </a>
        <div className="flex justify-between items-center lg:gap-16 md:gap-8 iPadPro:gap-8 gap-4">
          <a
            href="https://www.instagram.com/scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <a
            href="https://twitter.com/scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <a
            href="https://www.tiktok.com/@scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaTiktok
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <FaYoutube
            color="white"
            className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
          />
        </div>
      </div>
      <div className="flex justify-center items-center flex-1 md:px-[2.5rem] iPadPro:px-[4.5rem] lg:px-[7.5rem] gap-8">
        <div className="block flex-col justify-center items-start w-1/2 h-full gap-8 ">
          <p className="font-manrope font-bold text-[8vw] w-full text-white leading-normal">
            WANT TO{" "}
            <span className="text-[#7140F3] text-[8vw]">BRING IT ?</span>
          </p>
          <p className="text-white md:text-2xl lg:text-4xl font-semibold leading-[3.5rem]">
            Follow the App Store link
            <br />
            below to download
          </p>
          <div className="flex justify-around items-center w-full my-4">
            <a href="http://www.google.com">
              <img
                src={appStoreButton}
                alt="apple button"
                className="md:w-[150px] w-[200px]"
              />
            </a>
            <a
              href="/"
              className="shadow-xl self-center rounded-full bg-[#D6E854] text-black lg:text-[20px] md:text-[16px] 
                iPadPro:text-[16px] text-xs hover:text-[#D6E854] hover:bg-black hover:border-[#D6E854] 
                border border-black font-bold md:px-4 md:py-2 lg:px-8 lg:py-5"
            >
              SCOUT.FRL
            </a>
          </div>
        </div>
        <div className="flex justify-end w-1/2 h-full">
          <video
            src={video}
            autoPlay={"autoplay"}
            poster={image}
            controls={false}
            loop
            muted
            preload="auto"
            className="object-cover w-full h-full"
            playsInline={"playsinline"}
          />
        </div>
      </div>
      <div className="flex flex-row md:items-center w-full/2">
        <p className="my-20 px-4 text-white text-sm lg:text-[16px] lg:pl-24">
          © 2023 SCOUT All rights reserved
        </p>
        <a
          href="/"
          className="lg:my-20 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          Home
        </a>
        <a
          href="/privacy-policy"
          className="my-20 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          Privacy Policy
        </a>
        <a
          href="https://scout.simple.ink/"
          target="_blank"
          rel="noreferrer"
          className="my-20 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          Support
        </a>
        <a
          href="https://app.termly.io/document/eula/b19a4585-0385-486f-9087-3e75de9728ae"
          target="_blank"
          rel="noreferrer"
          className="my-20 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          End User Licensing Agreement
        </a>
      </div>
    </div>
  );
};

export default Share;
