import React from "react";
import logo from "../assets/logo.png";
import logoMobile from "../assets/logo_mobile.png";
import image from "../assets/image.jpg";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import image5 from "../assets/image5.png";
import image6 from "../assets/image6.png";
import image7 from "../assets/image7.png";
import image8 from "../assets/image8.png";
import image9 from "../assets/image9.png";
import gif from "../assets/bringIt.gif";
import video from "../assets/video.mp4";
import { FaTiktok, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";

const Home = () => {
  return (
    <div className="bg-black min-h-screen flex flex-col font-manrope">
      <div className="fixed w-full flex justify-between items-center p-4 bg-black z-20 iPadPro:p-4 lg:px-24">
        <a href="/">
          <img
            src={logo}
            alt="logo"
            className="hidden lg:block lg:h-[40px] h-[30px] cursor-pointer"
          />
          <img
            src={logoMobile}
            alt="logoMobile"
            className="lg:hidden block lg:h-[40px] h-[30px] cursor-pointer"
          />
        </a>
        <div className="flex justify-between items-center lg:gap-16 md:gap-8 iPadPro:gap-8 gap-4">
          <a
            href="https://www.instagram.com/scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <a
            href="https://twitter.com/scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <a
            href="https://www.tiktok.com/@scoutfrl"
            target="_blank"
            rel="noreferrer"
          >
            <FaTiktok
              color="white"
              className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
            />
          </a>
          <FaYoutube
            color="white"
            className="lg:h-[25px] lg:w-[25px] md:h-[23px] md:w-[23px] iPadPro:h-[23px] iPadPro:w-[23px] h-[20px] w-[20px] cursor-pointer"
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 lg:gap-16 text-white font-bold text-4xl md:text-7xl iPadPro:text-8xl lg:text-9xl w-full p-4 z-10 mt-12 iPadPro:mt-16 lg:mt-32 iPadPro:p-4 lg:px-24">
        <p>BRING RUGBY.</p>
        <p>BRING FOOTBALL.</p>
        <p className="text-[#7140F3]">BRING IT.</p>
      </div>
      <button
        type="button"
        data-tally-open="w5ZQYv"
        data-tally-width="420"
        data-tally-overlay="1"
        data-tally-emoji-text="👋"
        data-tally-emoji-animation="wave"
        data-tally-auto-close="0"
        className="fixed shadow-xl lg:bottom-20 lg:right-28 bottom-10 right-10 
        self-end rounded-full bg-[#D6E854] text-black lg:text-[20px] md:text-[16px] 
        iPadPro:text-[16px] text-xs lg:py-4 md:py-2 iPadPro:py-2 lg:px-6 px-4 py-1 
        hover:text-[#D6E854] hover:bg-black hover:border-[#D6E854] border border-black z-20 font-bold"
      >
        BRING IT
      </button>
      <div className="relative bottom-[5rem] md:bottom-[5.4rem] iPadPro:bottom-[5.4rem] lg:bottom-[7.1rem] mt-11 h-[500px] lg:h-full">
        <video
          src={video}
          autoPlay={"autoplay"}
          poster={image}
          controls={false}
          loop
          muted
          preload="auto"
          className="object-cover w-full h-full"
          playsInline={"playsinline"}
        />
      </div>
      <p className="text-white text-[25px] md:text-5xl iPadPro:text-5xl lg:text-[90px] w-full z-10 px-4 lg:px-24 text-right font-bold">
        We do not believe human <br /> capacity is limited by{" "}
        <span className="text-[#D6E854]">region</span> <br /> or{" "}
        <span className="text-[#D6E854]">circumstances</span>.
      </p>

      <p className="text-white text-[30px] md:text-6xl iPadPro:text-6xl lg:text-[120px] w-[80%] z-10 uppercase mb-20 px-4 lg:pl-24 mt-12 lg:mt-28 font-bold">
        <span className="text-[#7140F3]">TALENT</span> EXISTS <br />
        EVERYWHERE…
      </p>

      <div className="flex flex-row gap-10 lg:gap-24 w-full px-4 lg:px-24 justify-center my-12 lg:my-24">
        <div className="flex flex-col gap-14">
          <img
            src={image2}
            alt="image2"
            className="lg:w-[260px] w-[160px] lg:h-[460px] h-[280px] object-cover rounded-[50px]"
          />
          <img
            src={image3}
            alt="image3"
            className="lg:w-[260px] w-[160px] lg:h-[460px] h-[280px] object-cover rounded-[50px]"
          />
        </div>
        <div className="flex flex-col gap-14 mt-12 lg:mt-24">
          <img
            src={image4}
            alt="image4"
            className="lg:w-[260px] w-[160px] lg:h-[460px] h-[280px] object-cover rounded-[50px]"
          />
          <img
            src={image5}
            alt="image5"
            className="lg:w-[260px] w-[160px] lg:h-[460px] h-[280px] object-cover rounded-[50px]"
          />
        </div>
        <div className="hidden md:flex lg:flex flex-col gap-14">
          <img
            src={image6}
            alt="image6"
            className="md:w-[160px] md:h-[260px] iPadPro:w-[260px] iPadPro:h-[460px] lg:w-[260px] lg:h-[460px] object-cover rounded-[50px]"
          />
          <img
            src={image7}
            alt="image7"
            className="md:w-[160px] md:h-[260px] iPadPro:w-[260px] iPadPro:h-[460px] lg:w-[260px] lg:h-[460px] object-cover rounded-[50px]"
          />
        </div>
        <div className="hidden iPadPro:hidden lg:flex flex-col gap-14 mt-24">
          <img
            src={image8}
            alt="image8"
            className="w-[260px] h-[460px] object-cover rounded-[50px]"
          />
          <img
            src={image9}
            alt="image9"
            className="w-[260px] h-[460px] object-cover rounded-[50px]"
          />
        </div>
      </div>

      <div className="mt-10 flex flex-col p-8 lg:pl-24 lg:pr-0">
        <p className="text-white text-[25px] md:text-5xl iPadPro:text-5xl iPadPro:h-[260px] lg:text-[90px] w-full z-10 font-bold">
          … but <span className="text-[#D6E854]">talent discovery</span> is{" "}
          <br /> not yet widespread.
        </p>
        <img
          src={gif}
          alt="background"
          className="w-[70%] self-end relative lg:bottom-32 bottom-10"
        />
      </div>
      <p className=" text-white text-[25px] md:text-5xl iPadPro:text-5xl lg:text-[90px] w-[80%] z-10 uppercase mb-20 px-4 lg:pl-24 font-bold">
        <span className="text-[#7140F3]">Mission</span> <br />
        To enable billions to bring their talent to the world.
      </p>
      <div className="flex flex-col lg:flex-row items-start lg:items-center w-full/2">
        <p className="lg:my-20 mt-11 px-4 text-white text-sm lg:text-[16px] lg:pl-24">
          © 2023 SCOUT All rights reserved
        </p>
        <a
          href="/privacy-policy"
          className="lg:my-20 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          Privacy Policy
        </a>
        <a
          href="https://scout.simple.ink/"
          target="_blank"
          rel="noreferrer"
          className="lg:my-20 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          Support
        </a>
        <a
          href="https://app.termly.io/document/eula/b19a4585-0385-486f-9087-3e75de9728ae"
          target="_blank"
          rel="noreferrer"
          className="lg:my-20 mb-11 px-4 text-white text-sm lg:text-[16px] lg:pl-24"
        >
          End User Licensing Agreement
        </a>
      </div>
    </div>
  );
};

export default Home;
