import { BrowserRouter, Routes, Route } from "react-router-dom";

import { routes } from "./routes";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => (
          <Route {...route} key={index} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
